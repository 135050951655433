import React from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import DefaultHero from "../components/common/default-hero"
import { graphql } from "gatsby"
import ArticlesSection from "../components/common/articles-section"
import FullWidthView from "../components/common/full-width-view"

export const query = graphql`
  {
    prismic {
      allHyras {
        edges {
          node {
            hero_background_image
            hero_background_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            hero_title
            hero_information
            articles {
              title
              information
            }
            articles_image
            articles_imageSharp {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            divider_image
            divider_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
const Hyra = ({ data }) => {
  const hyra = data.prismic.allHyras.edges[0].node
  return (
    <Layout currentSiteUrl="/hyra">
      <SEO title='Hyra' />
      <DefaultHero heroImg={hyra.hero_background_imageSharp.childImageSharp.fluid} heroTitle={hyra.hero_title} heroInformation={hyra.hero_information} />
      <ArticlesSection articles={hyra.articles} fluidImg={hyra.articles_imageSharp.childImageSharp.fluid} leftWidth={4} />
      <FullWidthView marginBottom='10rem' fluidImg={hyra.divider_imageSharp.childImageSharp.fluid} />
    </Layout>
  )
}
export default Hyra
